<template>
  <div class="order-select-container">
    <!-- 购买订单 -->
    <div
      @click="goOrderDetails('/order')"
      class="order-select-item row-between"
      style="margin-top:1.25rem;"
    >
      <div class="row-center">
        <img
          class="order-select-img"
          src="../assets/icon/order/buy-order.png"
        />
        <p class="order-select-title">购买订单</p>

      </div>
      <img
        class="next-img"
        src="../assets/icon/next.png"
      />
    </div>
    <!-- 赠送订单 -->
    <div
      @click="goOrderDetails('/donationOrder')"
      class="order-select-item row-between"
    >
      <div class="row-center">
        <img
          class="order-select-img"
          src="../assets/icon/order/send-order.png"
        />
        <p class="order-select-title">赠送订单</p>

      </div>
      <img
        class="next-img"
        src="../assets/icon/next.png"
      />
    </div>
    <!-- 赠送订单 -->
    <div
      @click="goOrderDetails('/compositeOrder')"
      class="order-select-item row-between"
    >
      <div class="row-center">
        <img
          class="order-select-img"
          src="../assets/icon/composite/composite_1.png"
        />
        <p class="order-select-title">合成订单</p>

      </div>
      <img
        class="next-img"
        src="../assets/icon/next.png"
      />
    </div>
    <!-- 盲盒订单 -->
    <div
      @click="goOrderDetails('/blindOrder')"
      class="order-select-item row-between"
    >
      <div class="row-center">
        <img
          class="order-select-img"
          src="../assets/icon/blindBox/blindBox.png"
        />
        <p class="order-select-title">盲盒订单</p>

      </div>
      <img
        class="next-img"
        src="../assets/icon/next.png"
      />
    </div>
    <!-- 兑换订单 -->
    <div
      @click="goOrderDetails('/exchangeOrder')"
      class="order-select-item row-between"
    >
      <div class="row-center">
        <img
          class="order-select-img"
          src="../assets/icon/my/exchange.png"
        />
        <p class="order-select-title">兑换订单</p>

      </div>
      <img
        class="next-img"
        src="../assets/icon/next.png"
      />
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  mounted () {
    this.$store.commit('HIDE_APPLOADING')
  },
  methods: {
    goOrderDetails (e) {
      if (e == '/exchangeOrder') {
        localStorage.setItem('orderIndex','-7');
      } else {
        localStorage.setItem('orderIndex','1');
      }
      this.$router.push(e)
    }
  },
}
</script>
<style lang="less" scoped>
.order-select-container {
  margin-bottom: 3.125rem;
  margin-top: 3.875rem;
  height: calc(100vh - 7rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .order-select-item {
    width: 90%;
    height: 3.125rem;
    background: #262728;
    border-radius: 8px;
    margin: 0.75rem 5% 0 5%;
    .order-select-img {
      width: 1.25rem;
      height: auto;
      margin-left: 0.875rem;
      margin-right: 0.8125rem;
    }
    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: #f0f0f0;
      line-height: 1.25rem;
    }
    .next-img {
      width: 0.5rem;
      height: auto;
      margin-right: 1rem;
    }
  }
}
</style>